/* DIN Round W01 Bold */
@font-face {
  font-family: 'DIN Round W01 Bold';
  font-display: swap;
  src: url('/fonts/DINRoundW01Regular.woff2') format('woff2'), url('/fonts/DINRoundW01Regular.woff') format('woff');
}
/* DIN Round W01 Regular */
@font-face {
  font-family: 'DIN Round W01 Regular';
  font-display: swap;
  src: url('/fonts/DINRoundW01Regular.woff2') format('woff2'), url('/fonts/DINRoundW01Regular.woff') format('woff');
}

@font-face {
	font-family: 'HCo Gotham';
	src:url('/fonts/Gotham-Book_Web.woff2') format('woff2'),
		url('/fonts/Gotham-Book_Web.woff') format('woff');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'HCo Gotham';
	src:url('/fonts/Gotham-Black_Web.woff2') format('woff2'),
		url('/fonts/Gotham-Black_Web.woff') format('woff');
	font-weight: 800;
	font-style: normal;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: "DIN Round W01 Bold", Arial, Helvetica, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

strong {
  font-family: "DIN Round W01 Bold";
}
